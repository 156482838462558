import React from "react";

import { Link, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import TypographyHeadline from "../components/1-atoms/TypographyHeadline/TypographyHeadline";
import RichText from "../components/2-molecules/RichText/RichText";

export default function Home(props: PageProps): JSX.Element {
  return (
    <>
      <GatsbySeo
        title="Rechtliche Hinweise und Haftungsausschluss | Hautstark"
        nofollow
        noindex
      />

      <RichText>
        <TypographyHeadline size="h1">
          Rechtliche Hinweise und Haftungsausschluss für die Website von
          Hautstark
        </TypographyHeadline>

        <p>
          <strong>Finanzierung</strong>
          <br />
          Die Website www.hautstark.com (im Folgenden: „Website“) wird von
          PIERRE FABRE DERMO-Kosmetik (im Folgenden: „PFDK“) finanziert, einem
          Unternehmen der Pharmaindustrie, dessen Einkommen sich aus den von ihm
          angebotenen Dienstleistungen und den von ihm verkauften Produkten
          ergibt.
          <br />
          Die Website zeigt keine Werbung an und akzeptiert oder erhält keine
          Finanzierung durch Werbegelder.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Allgemeine Nutzungsbedingungen für die Website von Hautstark
        </TypographyHeadline>

        <p>
          <strong>Finanzierung</strong>
          <br />
          Die Website www.hautstark.com (im Folgenden: „Website“) wird von
          PIERRE FABRE DERMO-Kosmetik (im Folgenden: „PFDK“) finanziert, einem
          Unternehmen der Pharmaindustrie, dessen Einkommen sich aus den von ihm
          angebotenen Dienstleistungen und den von ihm verkauften Produkten
          ergibt.
          <br />
          Die Website zeigt keine Werbung an und akzeptiert oder erhält keine
          Finanzierung durch Werbegelder.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 1 - Zweck der allgemeinen Nutzungsbedingungen
        </TypographyHeadline>

        <p>
          Zweck dieser Allgemeinen Nutzungsbedingungen (im Folgenden:
          „Allgemeine Nutzungsbedingungen“) ist es, die Bedingungen für die
          Nutzung und den Aufruf der Website Pierre Fabre Dermo-Kosmetik GmbH
          (im Folgenden: Pierre Fabre Dermo-Kosmetik GmbH ) festzulegen, die von
          den Nutzern der Website (im Folgenden: <strong>„Nutzer“</strong>)
          akzeptiert werden.
          <br />
          Wenn ein Nutzer die Allgemeinen Nutzungsbedingungen nicht akzeptieren,
          fordert die Pierre Fabre Dermo-Kosmetik GmbH den Nutzer auf, die
          Website zu verlassen.
        </p>
        <p>
          Die Allgemeinen Nutzungsbedingungen unterliegen dem deutschen Recht
          und können jederzeit und ohne Vorankündigung geändert werden. <br />
          Die Website darf ausschließlich für private und nichtkommerzielle
          Zwecke genutzt und konsultiert werden.
        </p>
        <p>
          Dem Nutzer ist es generell untersagt, die Website ganz oder teilweise
          für rechtswidrige Zwecke (in Bezug auf das Urheberrecht) oder für
          Zwecke zu nutzen, die gegen diese Allgemeinen Nutzungsbedingungen
          verstoßen.
        </p>
        <p>
          Durch die Nutzung der Website erklärt sich der Nutzer damit
          einverstanden, die Allgemeinen Nutzungsbedingungen in der am Tag der
          Nutzung geltenden Fassung einzuhalten.
        </p>
        <p>
          Die Allgemeinen Nutzungsbedingungen sind gegen jeden Nutzer
          durchsetzbar, der eine Verbindung zur Website herstellt.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 2 - Beschreibung der Website
        </TypographyHeadline>

        <p>
          Auf die Website darf nur online unter der folgenden Adresse
          zugegriffen werden: www.hautstark.com.
        </p>
        <p>
          Die Website wurde erstellt, um Sie mit Informationen über die Pierre
          Fabre Dermo-Kosmetik GmbH zu versorgen (Produktblätter, Leitfäden,
          Spiele, Wettbewerbe, Newsletter usw.) und um die verschiedenen
          Produktlinien der Marke HAUTSTARK® umfassend zu präsentieren.
          Insbesondere stellt die Website außervertragliche Informationen über
          die Produkte der Marke und Beschreibungen zu all ihren Produkten
          bereit, nicht jedoch mit dem Ziel, einen direkten Kauf von Produkten
          oder Dienstleistungen anzubieten (sofern nicht ausdrücklich
          anderweitig angegeben). (Die genannten Zwecke bezeichnen wir im
          Folgenden als „Dienste“.)
        </p>
        <p>
          Wenn Sie minderjährig sind, verpflichten Sie sich, diese Website nur
          in Anwesenheit mindestens eines Elternteils oder gesetzlichen
          Vertreters zu besuchen, der diese Bedingungen zuerst akzeptieren muss.
          Wenn Sie unter sechzehn (16) Jahre alt sind, erklären Sie hiermit,
          dass Sie von Ihren Eltern oder gesetzlichen Vertretern bevollmächtigt
          wurden, der Pierre Fabre Dermo-Kosmetik GmbH Ihre personenbezogenen
          Daten mitzuteilen, um auf die Website zuzugreifen und Mitteilungen von
          Pierre Fabre Dermo-Kosmetik GmbH zu erhalten.
        </p>
        <p>
          Die Website wird vollständig von PFDK finanziert. Die Website stellt
          kein Vertragsangebot dar und akzeptiert keine Werbung.
        </p>
        <p>
          Sofern diese Website unter Berücksichtigung aller Aktivitäten
          aktualisiert wird, ist es möglich, dass neue Informationen, Dienste
          oder Ankündigungen zum Zeitpunkt ihrer Anzeige nicht mehr gültig oder
          einfach veraltet sind. Pierre Fabre Dermo-Kosmetik GmbH kann nicht
          garantieren, dass alle auf dieser Website angezeigten Informationen
          und Dienste vollkommen aktuell sind, obwohl alle erforderlichen
          Anstrengungen unternommen werden, um dies zu gewährleisten.
        </p>
        <p>
          Pierre Fabre Dermo-Kosmetik GmbH kann nicht für die Richtigkeit und
          Vollständigkeit der auf dieser Website dargestellten Informationen und
          Diensten garantieren. Pierre Fabre Dermo-Kosmetik GmbH führt die
          notwendigen Berichtigungen durch. Die veröffentlichten und angebotenen
          Informationen und Dienste können auch im Zeitraum zwischen der ersten
          Wahrnehmung durch den Nutzer und ihrer Lektüre durch den Nutzer
          aktualisiert werden. Pierre Fabre Dermo-Kosmetik GmbH kann daher in
          keiner Weise dafür garantieren, dass diese Informationen und diese
          Dienste stets korrekt, vollständig und aktuell sind.
        </p>
        <p>
          Der Nutzer ist in vollem Umfang und ausschließlich selbst für die
          Nutzung der Website verantwortlich. Pierre Fabre Dermo-Kosmetik GmbH
          haftet nicht für Ergebnisse, die durch die Nutzung der Website
          entstehen.
        </p>
        <p>
          Sofern nicht ausdrücklich anders angegeben, unterliegt jedes neue
          Merkmal, das eine oder mehrere der bestehenden Dienste oder von Pierre
          Fabre Dermo-Kosmetik GmbH neu angebotene Dienste verbessert oder
          ausweitet, diesen Allgemeinen Nutzungsbedingungen. Der Nutzer
          akzeptiert die Tatsache, dass ihm der Dienst ohne Mängelgewähr zur
          Verfügung gestellt wird. Der Nutzer verpflichtet sich, die Website in
          Übereinstimmung mit dem in den Allgemeinen Nutzungsbedingungen
          beschriebenen Zweck zu nutzen, den Zweck der Website nicht zu
          ignorieren und nicht zu versuchen, Nutzer auf einen anderen Dienst
          umzuleiten.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 3 - Methoden Für Den Zugriff Auf Die Website
        </TypographyHeadline>

        <p>
          <strong>3.1. </strong>Um auf die Website zugreifen zu können, muss der
          Nutzer über die für die Internetnutzung erforderliche Hardware und
          Software verfügen. Um mit einem Computer, Tablet oder Mobiltelefon (im
          Folgenden: „Hardware“) auf die Website zugreifen zu können, muss der
          Nutzer über einen ausreichend schnellen Internetanschluss und einen
          Telefondienst für den Internetzugang per Computer oder Tablet oder
          über eine WAP-, Wi-Fi- und/oder 3G/4G-Verbindung für den
          Internetzugang per Mobiltelefon (z. B. ein Smartphone unter iOS oder
          Android) verfügen.
        </p>

        <p>
          Der Nutzer erkennt an, dass die Bestimmungen seines Vertrags mit dem
          Internet-/Mobilfunkanbieter bei der Nutzung der Website weiterhin
          gelten. <br />
          Der Nutzer alleine trägt alle Kosten, die ein Anbieter für den Zugang
          zu Internet-, Wireless-, WAP- und/oder 3G/4G-Verbindungsdiensten zur
          Nutzung der Website geltend machen kann. <br />
          Der Nutzer darf bei der Nutzung der Website den ordnungsgemäßen
          Betrieb der Website, der Server oder der Netzwerke nicht behindern
          oder stören und/oder die Website ändern, anpassen oder davon
          Raubkopien anfertigen und/oder eine Anwendung oder Website eines
          Drittanbieters so verändern, dass Verwirrung über deren Beziehung zur
          hier beschriebenen Website entsteht.
        </p>
        <p>
          Der Nutzer ist sich der Einschränkungen und Grenzen der Internet- und
          Telekommunikationsnetze bewusst und verpflichtet sich daher:
        </p>
        <ul>
          <li>
            seine Hardware vor Viren jeglicher Art, Angriffsversuchen und
            unbefugtem Zugriff und/oder Nutzung durch Dritte zu schützen;
          </li>
          <li>
            dafür zu sorgen, dass seine Hardware so installiert wurde, betrieben
            und gewartet wird, dass die Website zufriedenstellend funktionieren
            kann.
          </li>
        </ul>

        <strong>3.2. Verpflichtungen des Nutzers</strong>

        <p>
          Während der Nutzung der Website ist dem Nutzer Folgendes untersagt:
        </p>
        <ul>
          <li>
            die Nutzung der Website und der Dienste für politische,
            propagandistische oder missionierende Zwecke;
          </li>
          <li>eine Zweckentfremdung der Website vorzunehmen;</li>
          <li>
            illegalen Aktivitäten nachzugehen, insbesondere durch die Verletzung
            von Rechten in Bezug auf Texte, Fotos, Bilder, Videos usw.;
          </li>
          <li>
            Werbung für einen anderen Dienst zu betreiben oder andere Nutzer
            dazu zu verleiten, ihn zu nutzen, unabhängig davon, ob es sich bei
            diesem anderen Dienst um einen Wettbewerber handelt oder nicht;
          </li>
          <li>den Betrieb der Website in irgendeiner Weise zu gefährden;</li>
          <li>die Gesetze über geistiges Eigentum zu verletzen;</li>
          <li>gegen geltende Gesetze und Vorschriften zu verstoßen.</li>
        </ul>

        <p>Den Nutzern ist zudem Folgendes untersagt:</p>
        <ul>
          <li>
            Herunterladen, Senden oder Übertragen von illegalen, schädlichen,
            bedrohlichen oder missbräuchlichen, belästigenden, diffamierenden,
            vulgären, obszönen, hasserfüllten, rassistischen oder anderweitig
            verwerflichen oder die Privatsphäre einer anderen Person
            gefährdenden Inhalten per E-Mail oder auf andere Weise;
          </li>
          <li>
            Betrachten, Anzeigen, Herunterladen oder Übertragen von Inhalten,
            die gegen die in Frankreich geltenden Gesetze, insbesondere die
            Gesetze zum Schutz geistigen Eigentums, verstoßen;
          </li>
          <li>
            Versuchen, andere Nutzer durch widerrechtliche Aneignung des Namens
            bzw. Firmennamens einer anderen natürlichen oder juristischen
            Personen zu täuschen, insbesondere wenn der Nutzer sich als
            Mitarbeiter oder Bevollmächtigter von Pierre Fabre Dermo-Kosmetik
            GmbH, als Moderator, Berater oder Host ausgibt;
          </li>
          <li>
            Herunterladen, Anzeigen oder Übertragen von Inhalten (per E-Mail
            oder auf andere Weise), die Computerviren oder sonstigen Code,
            Dateien oder Programme enthalten, die dazu bestimmt sind, das
            Funktionieren von Software oder Telekommunikationsgeräten zu
            unterbrechen, zu zerstören oder einzuschränken, um nur einige
            Beispiele zu nennen;
          </li>
          <li>
            Herunterladen, Anzeigen oder Übertragen von Inhalten (per E-Mail
            oder auf andere Weise), die Patente, eingetragene Marken,
            Geschäftsgeheimnisse, geistige Eigentumsrechte oder andere
            Eigentumsrechte (im Folgenden: „Rechte“) Dritter verletzen;
          </li>
          <li>
            Störung des normalen Gesprächsverlaufs, Erhöhung der
            Scrollgeschwindigkeit des Bildschirms mit dem Ziel, Nutzern die
            Verfolgung und Eingabe von Kommentaren unmöglich zu machen, sowie
            andere Handlungen, die ähnlich störend sind und die Fähigkeit der
            Nutzer zur Kommunikation in Echtzeit beeinträchtigen;
          </li>
          <li>
            Behinderung oder Störung des Dienstes oder der mit dem Dienst
            verbundenen Server oder Netzwerke oder die Weigerung, die
            erforderlichen Bedingungen, Verfahren, allgemeinen Regeln oder
            Vorschriften einzuhalten, die für die mit dem Dienst verbundenen
            Netzwerke gelten;
          </li>
          <li>Belästigung anderer Nutzer in welcher Weise aus immer;</li>
          <li>
            Sammeln und Speichern von personenbezogenen oder identifizierenden
            Daten anderer Nutzer.
          </li>
        </ul>

        <p>
          Pierre Fabre Dermo-Kosmetik GmbH oder eine von Pierre Fabre
          Dermo-Kosmetik GmbH benannte natürliche oder juristische Person hat
          das Recht, Inhalte zu löschen oder zu entfernen, die gegen die
          vorliegenden Bestimmungen und/oder die geltenden Vorschriften
          verstoßen und/oder auf andere Weise verwerflich sein können. Der
          Nutzer erkennt auch an, dass er ein gutes Urteilsvermögen ausüben und
          alle Risiken im Zusammenhang mit der Nutzung des Dienstes und der
          Website akzeptieren muss.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 4 - Auf der Website bereitgestellte Informationen
        </TypographyHeadline>

        <p>
          <strong>
            4.1. Informationen zu den Produkten und Dienstleistungen
          </strong>
          <br />
          Sofern nicht anders angegeben, stellen die auf der Website
          vorgestellten Produkte und Dienstleistungen eine allgemeine
          Präsentation der Produktlinie der Marke dar. Diese Inhalte dienen
          ausschließlich Informationszwecken und sind nicht vertraglich bindend.
        </p>
        <p>
          <strong>
            4.2.Nutzung von auf der Website bereitgestellten Ratschlägen
          </strong>
          <br />
          Dem Nutzer werden auf der Website Informationen bereitgestellt. Die
          Verfügbarkeit dieser Informationen stellt keine Übertragung von mit
          den Informationen verbundenen Eigentumsrechten dar. Dem Nutzer wird
          eine nicht ausschließliche Lizenz zur Einsichtnahme in diese
          Informationen und zur privaten Nutzung derselben gewährt. Insofern ist
          es dem Nutzer untersagt, die Informationen ganz oder teilweise, auf
          irgendeine Weise und unter Verwendung irgendeines bestehenden oder
          zukünftigen Mediums zu kopieren oder zu reproduzieren, die
          Informationen in eine andere Sprache zu übertragen und die
          Informationen abzuändern.
        </p>
        <p>
          Pierre Fabre Dermo-Kosmetik GmbH haftet nicht für direkte oder
          indirekte Folgen und/oder Schäden, die durch Fehlerhaftigkeit oder
          Piraterie der Informationen entstehen. Darüber hinaus ist Pierre Fabre
          Dermo-Kosmetik GmbH nicht an eine Ergebnispflicht gebunden, sondern
          unterliegt ausschließlich einer Sorgfaltspflicht in Bezug auf die dem
          Nutzer zur Verfügung gestellten Informationen und Ratschläge.
        </p>
        <p>
          Schließlich leistet Pierre Fabre Dermo-Kosmetik GmbH keinerlei
          ausdrückliche oder stillschweigende Garantie und übernimmt keinerlei
          Haftung für die Nutzung dieser Informationen und Ratschläge, die
          lediglich zu Informationszwecken zur Verfügung gestellt werden.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 5 - Garantien und Haftung
        </TypographyHeadline>

        <p>
          <strong>5.1 Haftung</strong>
          <br />
          Der Nutzer ist in vollem Umfang und ausschließlich selbst für die
          Nutzung der Website verantwortlich. Der Nutzer verpflichtet sich, die
          Website bestimmungsgemäß zu nutzen. Pierre Fabre Dermo-Kosmetik GmbH
          garantiert dem Nutzer, dass er von der ungestörten Nutzung der Website
          profitieren wird, und dass die Website insbesondere keine Rechte
          Dritter an geistigem Eigentum verletzt und dass die Website ein
          Originalwerk ist. Pierre Fabre Dermo-Kosmetik GmbH garantiert, dass
          die Website den geltenden französischen Gesetzen und den geltenden
          Branchenstandards für Websites entspricht.
        </p>
        <p>
          Der Nutzer verpflichtet sich, keine Viren, Bugs oder sonstigen wie
          auch immer gearteten Dateien, die den Betrieb der Website stören
          könnten, vorsätzlich oder auch unfreiwillig einzubringen; in solchen
          Fällen trägt der Nutzer die volle Verantwortung.
        </p>
        <p>
          Zum Schutz aller Nutzer kann Pierre Fabre Dermo-Kosmetik GmbH
          skrupellose Nutzer verfolgen.
        </p>
        <p>
          Der Nutzer erklärt und garantiert, dass er sich über die
          Besonderheiten und Einschränkungen des Internets vollkommenen im
          Klaren ist und insbesondere weiß, dass die Übertragung von Daten und
          Informationen im Internet nur eine relative technische Zuverlässigkeit
          aufweist, da hierbei heterogene Netze mit unterschiedlichen Merkmalen
          und technischen Möglichkeiten genutzt werden, was den Zugang zu
          bestimmten Zeiten behindern oder unmöglich machen kann.
        </p>
        <p>
          Pierre Fabre Dermo-Kosmetik GmbH haftet nicht für Schäden, die sich
          aus dem Aufruf oder der Verwendung der Website durch einen Nutzer
          ergeben. <br />
          Pierre Fabre Dermo-Kosmetik GmbH haftet nicht für Fehler,
          Auslassungen, Viren oder Ergebnisse, die durch unsachgemäße Nutzung
          der Dienste, Informationen und/oder der Website an sich entstehen
          können. Für Pierre Fabre Dermo-Kosmetik GmbH gilt lediglich eine
          allgemeine Sorgfaltspflicht. <br />
          Pierre Fabre Dermo-Kosmetik GmbH und die Führungskräfte und
          Mitarbeiter von Pierre Fabre Dermo-Kosmetik GmbH haften nicht:
        </p>
        <ul>
          <li>
            für Schäden jeglicher Art, die sich aus einer Verbindung zur Website
            oder dem Aufrufen der Website ergeben;
          </li>
          <li>
            für Schäden, die direkt oder indirekt aus dem Bezug von
            Informationen über die Website oder der Nutzung der auf besagter
            Website verfügbaren Dienste entstehen;
          </li>
          <li>
            für direkte oder indirekte Schäden, die sich aus der Nutzung der
            Website ergeben, insbesondere für alle betrieblichen, finanziellen
            oder kommerziellen Verluste oder den Verlust der Website oder von
            Daten eines Informationssystems, auch wenn Pierre Fabre
            Dermo-Kosmetik GmbH über die Möglichkeit eines solchen Schadens
            informiert wurde.
          </li>
        </ul>
        <p>
          Pierre Fabre Dermo-Kosmetik GmbH haftet unter keinen Umständen für den
          Verlust von Informationen/Diensten oder für Einbußen im Zusammenhang
          mit dem besagten Dienst. Informationen oder Dienstleistungen, die von
          der Website zur Verfügung gestellt werden, sind keinesfalls als eine
          Form der Garantie auszulegen.
          <br />
          Vorbehaltlich der geltenden Gesetze und Bestimmungen haftet Pierre
          Fabre Dermo-Kosmetik GmbH für keinerlei Schäden, insbesondere nicht
          für entgangenen Gewinn, verlorene Kunden, Daten oder immaterielle
          Vermögenswerte (um nur einige zu nennen), die durch die Nutzung oder
          die Unmöglichkeit der Nutzung der Website und, ganz allgemein
          gesprochen, durch irgendwelche Ereignisse im Zusammenhang mit der
          Website und/oder der Website eines Dritten entstehen können.
        </p>

        <p>
          <strong>5.2. Änderung oder Schliessung der Website</strong>
          <br />
          Pierre Fabre Dermo-Kosmetik GmbH ist bestrebt, seine Website rund um
          die Uhr zugänglich zu halten, ist dazu aber nicht verpflichtet. Pierre
          Fabre Dermo-Kosmetik GmbH kann daher den Zugang unterbrechen,
          insbesondere für Wartungsarbeiten und Upgrades. Pierre Fabre
          Dermo-Kosmetik GmbH haftet unter keinen Umständen für solche
          Unterbrechungen und deren Folgen für Nutzer oder Dritte.
        </p>
        <p>
          Pierre Fabre Dermo-Kosmetik GmbH behält sich das Recht vor, die
          Website ohne vorherige Ankündigung und ohne jegliche Haftung zu
          verändern oder zu entfernen.
        </p>
        <p>
          Die Entfernung oder Änderung der Website stellt in keiner Weise einen
          Grund für den Nutzer dar, eine bestimmte Entschädigung zu verlangen
          oder einen Schaden geltend zu machen.
        </p>

        <p>
          <strong>5.3. Garantien</strong>
          <br />
          Der Nutzer erkennt die folgenden Sachverhalte ausdrücklich an und
          akzeptiert diese:
        </p>
        <ul>
          <li>
            Die Nutzung der Website und der Dienste geschieht auf eigene Gefahr
            des Nutzers. Der Dienst wird ohne Mängelgewähr bereitgestellt und
            kann nur bei Verfügbarkeit aufgerufen werden. HAUTSTARK gibt keine
            ausdrückliche oder stillschweigende Garantie der Qualität oder
            Eignung des Dienstes für einen bestimmten Zweck und der
            Nichtverletzung der Nutzungsbedingungen durch die Nutzer des
            Dienstes (um nur einige Beispiele zu nennen).
          </li>

          <li>
            Pierre Fabre Dermo-Kosmetik GmbH garantiert nicht dafür, dass die
            Website und/oder die Dienste den Erwartungen des Nutzers entsprechen
            werden; dass sie unterbrechungsfrei, zeitnah, gefahrlos oder
            vollkommen fehlerfrei genutzt werden können; dass die durch die
            Nutzung der Dienste erzielbaren Ergebnisse zutreffend und
            zuverlässig sein werden; dass die Qualität aller vom Nutzer aufgrund
            dieses Dienstes bezogenen Dienste, Informationen oder sonstigen
            Materialien seinen Erwartungen entsprechen werden; oder dass in der
            verwendeten Software möglicherweise enthaltene Fehler einer
            Berichtigung zugeführt werden.
          </li>

          <li>
            Das Herunterladen oder ein anderweitiger Bezug von Materialien bei
            der Nutzung des Dienstes geschieht auf eigene Gefahr des Nutzers.
            Der Nutzer trägt die alleinige Verantwortung für etwaige Schäden
            oder Datenverluste, die aus dem Herunterladen der besagten
            Materialien auf seinen Computer entstehen können.
          </li>
        </ul>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          5.4. Kommunikationsforen
        </TypographyHeadline>

        <p>
          Die Pierre Fabre Dermo-Kosmetik GmbH stellt dem Nutzer auf der Website
          Kommunikationsforen zur Verfügung, über die er sich unter der
          folgenden Adresse an die Pierre Fabre Dermo-Kosmetik GmbH wenden oder
          Fragen stellen kann: contact.ducray@pierre-fabre.com. Alle in diesen
          Kommunikationsforen geposteten Beiträge drücken die Gedanken der
          jeweiligen Verfasser aus. Die Pierre Fabre Dermo-Kosmetik GmbH behält
          sich jedoch als Verantwortlicher für den Inhalt dieser Räume das Recht
          vor, auf Nachrichten nicht zu antworten, die als verwerflich,
          rechtswidrig oder nicht im Einklang mit diesen Allgemeinen
          Nutzungsbedingungen angesehen werden können.
        </p>
        <p>
          Jegliche Beeinträchtigung des Ansehens oder Rufs von Pierre Fabre
          Dermo-Kosmetik GmbH und/oder von PFDK, die sich aus vom Nutzer
          eingestellten Inhalten ergibt, kann zu einer Strafverfolgung führen.
        </p>
        <p>
          Die Pierre Fabre Dermo-Kosmetik GmbH haftet nicht für die in diesen
          Kommunikationsforen dargestellten Informationen und Inhalte. Die
          Pierre Fabre Dermo-Kosmetik GmbH behält sich das Recht vor, alle
          Nachrichten zu löschen, insbesondere wenn diese Nachrichten Rechte
          Dritter verletzen, nicht den redaktionellen Richtlinien der Website
          entsprechen oder gegen den vorstehenden Artikel 3.2 verstoßen.
        </p>
        <p>Der Nutzer verpflichtet sich daher:</p>
        <ul>
          <li>
            keine Inhalte zu veröffentlichen, die die Menschenwürde verletzen
            könnten;
          </li>
          <li>dem privaten Charakter der Inhalte gerecht zu werden;</li>
          <li>
            die Verletzung von Gesetzen zum Schutz geistigen Eigentums zu
            unterlassen;
          </li>
          <li>
            die Verletzung von Gesetzen zur Aufrechterhaltung der öffentlichen
            Ordnung
          </li>
          <li>die geltenden Gesetze und Vorschriften einzuhalten;</li>
          <li>den Betrieb der Website in keiner Weise zu gefährden;</li>
          <li>
            seine Anmeldedaten nicht an andere Nutzer oder sonstige Personen
          </li>
          <li>
            keine Werbung für einen anderen Dienst zu betreiben und keine
            anderen
          </li>
        </ul>
        <p>
          Der Nutzer gewährt der Pierre Fabre Dermo-Kosmetik GmbH hiermit eine
          kostenlose, unwiderrufliche, nicht ausschließliche, weltweite Lizenz
          für einen Zeitraum von 10 Jahren, um die besagten
          Bewertungen/Meinungen zu reproduzieren, darzustellen, zu verwenden, zu
          kopieren, zu modifizieren, anzupassen, zu übersetzen, aus ihnen
          abgeleitete Werke zu erstellen, in andere Werke einzugliedern und die
          Inhalte (ganz oder teilweise) zu verteilen und auf andere Weise zu
          verwenden und/oder alle wie auch immer gearteten Veröffentlichungen in
          den Kommunikationsforen in andere Materialien, Medien oder
          Technologien einzubinden.
        </p>
        <p>
          Der Nutzer akzeptiert hiermit, dass die Pierre Fabre Dermo-Kosmetik
          GmbH seine Veröffentlichungen insbesondere auf den Social-Media-Seiten
          von Pierre Fabre Dermo-Kosmetik GmbH auf Facebook und Instagram, in
          seinen Newslettern und in allen ausländischen und/oder verwandten
          Versionen seiner Website übersetzen und veröffentlichen kann.
        </p>
        <p>
          Der Nutzer wird hiermit darauf hingewiesen, dass eine solche
          Veröffentlichung seinen Namen (oder das weiter oben empfohlene
          Pseudonym) enthalten kann, wie bei seinen Veröffentlichungen
          verwendet. Der Nutzer stimmt hiermit der Verwendung seiner
          Veröffentlichungen in Verbindung mit der Marke und den Logos der Marke
          gemäß den Bestimmungen des vorliegenden Absatzes zu.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 7 – Geistiges Eigentum
        </TypographyHeadline>

        <p>
          Sofern hierin nicht anderweitig festgelegt, sind alle Elemente der
          Website (insbesondere die Texte, Daten, Datenbanken, Grafiken, Logos,
          Marken, Namen, Animationen, Bilder, Videos, Töne, Software und andere
          Elemente) (im Folgenden: „Elemente“) ausschließliches Eigentum von der
          Pierre Fabre Dermo-Kosmetik GmbH und/oder der Dritten, die Pierre
          Fabre Dermo-Kosmetik GmbH eine Lizenz hierfür erteilt haben, und
          unterliegen dem französischen und internationalen Urheberrecht und im
          weiteren Sinne den Gesetzen über geistiges Eigentum.
        </p>
        <p>
          Diese Elemente sind durch Urheberrechte, Markenschutz, Patente,
          Datenbankrechte, Geschäftsgeheimnisse und/oder beliebige andere Rechte
          am geistigen Eigentum geschützt.
        </p>
        <p>
          Sämtliche Rechte der Vervielfältigung, Darstellung oder öffentlichen
          Mitteilung bleiben vorbehalten, einschließlich der visuellen,
          fotografischen, symbolischen oder sonstigen Darstellung. Es ist
          strengstens untersagt, diese Website ganz oder teilweise auf einem wie
          auch immer gearteten elektronischen Medium ohne ausdrückliche
          Genehmigung des Publikationsleiters zu reproduzieren.
        </p>
        <p>
          Die auf der Website verwendeten Marken und Logos sind eingetragen und
          ihre Reproduktion bedeutet eine Verletzung der entsprechenden
          Schutzrechte. Alle Nutzungsrechte sind vorbehalten. Gemäß Artikel
          L.122-4 des Französischen Gesetzes über geistiges Eigentum ist jede
          nicht ausdrücklich erlaubte Darstellung oder Reproduktion, ob
          vollständig oder teilweise, ungesetzlich und stellt nach Artikel L.
          335-2 ff. des Gesetzes über geistiges Eigentum eine strafbare Handlung
          dar.
        </p>
        <p>
          Dem Nutzer ist es nicht erlaubt, die Website zu verkaufen, zu
          kopieren, zu vermieten, zu vermarkten, zu übertragen, abzutreten oder
          anderweitig abzugeben, zu modifizieren oder anzupassen, sie ganz oder
          teilweise in andere Software zu integrieren, sie zu übersetzen, zu
          dekompilieren oder zu disassemblieren oder Software oder abgeleitete
          Merkmale der Website ohne die ausdrückliche schriftliche Genehmigung
          des Publikationsleiters zu erstellen.
        </p>
        <p>
          Pierre Fabre Dermo-Kosmetik GmbH gewährt dem Nutzer eine kostenlose,
          nicht exklusive, nicht übertragbare, persönliche Lizenz, um die
          Website und ihre Elemente zu ausschließlich privaten Zwecken
          aufzurufen, zu nutzen und anzuzeigen. Dieses Recht wird für die
          ausschließlich persönliche, private und nichtgewerbliche Nutzung
          erteilt und unterliegt dem Vorbehalt der Einhaltung aller
          diesbezüglichen Nutzungsbedingungen in Bezug auf
          Vervielfältigungsrechte und Rechte am geistigen Eigentum. Insofern ist
          es dem Nutzer untersagt, die Elemente ganz oder teilweise, auf welche
          Art auch immer und unter Verwendung irgendeines bestehenden oder
          zukünftigen Mediums zu kopieren und/oder zu reproduzieren, die
          Elemente in eine andere Sprache zu übertragen und die Elemente
          abzuändern.
        </p>
        <p>
          Jede sonstige Verwendung der Elemente ist strengstens verboten und
          stellt eine Verletzung der Bestimmungen des Gesetzes über geistiges
          Eigentum dar.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 7 – Personenbezogene Daten
        </TypographyHeadline>

        <p>
          <Link to="/datenschutz/">Siehe Datenschutzerklärung</Link>
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 8 - Hypertext-Links
        </TypographyHeadline>

        <p>
          <Link to="/datenschutz/">Siehe Datenschutzerklärung</Link>
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 9 - Richtlinie Für Cookies
        </TypographyHeadline>

        <p>
          <Link to="/datenschutz/">Siehe Datenschutzerklärung</Link>
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 10 - Verzichtsausschluss und teilweise Ungültigkeit
        </TypographyHeadline>

        <p>
          Sollte sich herausstellen, dass eine der Bestimmungen der Allgemeinen
          Nutzungsbedingungen aufgrund einer Gesetzesänderung oder eines
          Gerichtsbeschlusses nichtig ist, wird die Gültigkeit und Einhaltung
          der übrigen Bestimmungen der Allgemeinen Nutzungsbedingungen davon in
          keinem Fall berührt.
        </p>
        <p>
          Sollte eine der Parteien von einer der Bestimmungen der Allgemeinen
          Nutzungsbedingungen keinen Gebrauch machen, so bedeutet dies nicht,
          dass die Partei zu einem späteren Zeitpunkt erneut auf die gleiche
          Bestimmung verzichten wird.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 10 - Verzichtsausschluss und teilweise Ungültigkeit
        </TypographyHeadline>

        <p>
          Sollte sich herausstellen, dass eine der Bestimmungen der Allgemeinen
          Nutzungsbedingungen aufgrund einer Gesetzesänderung oder eines
          Gerichtsbeschlusses nichtig ist, wird die Gültigkeit und Einhaltung
          der übrigen Bestimmungen der Allgemeinen Nutzungsbedingungen davon in
          keinem Fall berührt.
        </p>
        <p>
          Sollte eine der Parteien von einer der Bestimmungen der Allgemeinen
          Nutzungsbedingungen keinen Gebrauch machen, so bedeutet dies nicht,
          dass die Partei zu einem späteren Zeitpunkt erneut auf die gleiche
          Bestimmung verzichten wird.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 11 - Salvatorische Klausel
        </TypographyHeadline>

        <p>
          Sollte eine Bestimmung dieser Allgemeinen Nutzungsbedingungen ihre
          Gültigkeit verlieren oder sich als mangelhaft erweisen, wird die
          betreffende Bestimmung durch eine Bestimmung ersetzt, die ihr in der
          rechtlichen Auslegung am nächsten kommt.
        </p>
        <p>
          Der Nutzer erklärt und erkennt an, dass er die Allgemeinen
          Nutzungsbedingungen gelesen hat und dass die Nutzung der Website die
          vollständige und bedingungslose Annahme aller Allgemeinen
          Nutzungsbedingungen durch den Nutzer impliziert.
        </p>
        <p>
          Fragen zu den Allgemeinen Nutzungsbedingungen, ebenso Fragen oder
          Kommentare zur Website oder zum Benutzerkonto, kann der Nutzer sich
          über das Kontaktformular ans uns wenden.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 12 - Änderung der Allgemeinen Nutzungsbedingungen
        </TypographyHeadline>

        <p>
          Die Pierre Fabre Dermo-Kosmetik GmbH kann die Allgemeinen
          Nutzungsbedingungen jederzeit ändern. Die Nutzer werden über diese
          Änderungen informiert. Jede Nutzung der Website nach Bekanntgabe einer
          Änderung gilt als Zustimmung zu den Allgemeinen Nutzungsbedingungen.
          Es gelten dann die Allgemeinen Nutzungsbedingungen, die zum Zeitpunkt
          der Nutzung durch den Nutzer auf der Website gelten. Sollte der Nutzer
          mit den Änderungen der Allgemeinen Nutzungsbedingungen nicht
          einverstanden sein, verpflichtet er sich, nicht auf die Website
          zuzugreifen.
        </p>
        <p>
          Falls die Allgemeinen Nutzungsbedingungen übersetzt werden, ist die
          französische Version die gültige, anwendbare Version.
        </p>
      </RichText>
      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Artikel 13 - Anwendbares Recht Und Gerichtsbarkeit
        </TypographyHeadline>

        <p>
          Die Allgemeinen Nutzungsbedingungen unterliegen dem deutschen Recht:
          Etwaige Streitigkeiten, die sich im Zusammenhang mit den Allgemeinen
          Nutzungsbedingungen ergeben, werden einer Mediation zugeführt, bevor
          ein Gerichts- oder Schiedsverfahren eingeleitet wird.
        </p>
        <p>
          Sollte die Mediation scheitern, so wird die Streitigkeit vor das
          zuständige Gericht in der Jurisdiktion des Pariser Berufungsgerichts
          gebracht.
        </p>
      </RichText>
    </>
  );
}
