// extracted by mini-css-extract-plugin
export var richText = "RichText-module--richText--oLoWT";
export var richTextFluid = "RichText-module--richText--fluid--nzqGR";
export var richTextFontSizeL = "RichText-module--richText--font-size-l--ZnrqS";
export var richTextSpacingBottomL = "RichText-module--richText--spacing-bottom-l--icy9m";
export var richTextSpacingL = "RichText-module--richText--spacing-l--uXnAi";
export var richTextSpacingM = "RichText-module--richText--spacing-m--htzY+";
export var richTextSpacingNone = "RichText-module--richText--spacing-none---7tTs";
export var richTextSpacingS = "RichText-module--richText--spacing-s--Ui1Ga";
export var richTextSpacingXs = "RichText-module--richText--spacing-xs--jHVUF";
export var richText__footnote = "RichText-module--richText__footnote--h6-Df";