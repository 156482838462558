import React from "react";
import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import * as css from "./RichText.module.scss";

export const richTextTags = ["h1", "h2", "h3", "h4"];
export const spacingTypes = ["none", "xs", "s", "m", "l", "bottom-l"];
export const fontSize = ["default", "l"];

export interface FootnoteProps {
  /** anchor link to footnote */
  href: string;
  /** id to be linked to */
  id: string;
  /** The footnotes label/text */
  children: string | React.ReactNode;
}

export interface FootnotesProps {
  /** Array of footnotes */
  notes?: Array<FootnoteProps>;
}
export interface RichTextProps {
  className?: string | undefined;
  /** The RichTexts children */
  children?: React.ReactNode;
  /** Let the component scale relative to the parent element */
  fluid?: boolean;
  /** Font Size Modifier */
  fontSize?: typeof fontSize[number];
  /** The RichTexts footnote */
  footnotes?: Array<FootnoteProps>;
  /** The RichTexts headline */
  headline?: React.ReactNode;
  /** The tag to use: h1, h2 or h3 */
  // TODO: change to headlineTag
  tag?: typeof richTextTags[number];
  /** Different margin spacings */
  spacing?: typeof spacingTypes[number];
  /** Slug ID from contentful */
  id?: string;
}

const RichText: React.FC<RichTextProps> = ({
  headline = null,
  children,
  footnotes,
  fontSize = "default",
  fluid = false,
  tag = "h1",
  spacing = "none",
  className,
  id,
}: RichTextProps) => {
  const Tag = `${tag}` as React.ReactNode;

  return (
    <div
      id={id}
      className={classNames(
        getClassName(css, "richText"),
        getClassName(css, `richText--spacing-${spacing}`),
        {
          [className as string]:
            typeof className === "string" && className.length > 0,
          [getClassName(css, "richText--fluid") as string]: fluid === true,
          [getClassName(css, `richText--font-size-${fontSize}`) as string]:
            typeof fontSize === "string" && fontSize !== "default",
        }
      )}
    >
      {headline ? <Tag>{headline}</Tag> : null}

      {children}

      <RichTextFootnotes notes={footnotes} />
    </div>
  );
};

// TODO: move footnotes to atoms
const RichTextFootnotes: React.FC<FootnotesProps> = ({
  notes,
}: FootnotesProps) => {
  if (!Array.isArray(notes) || notes.length < 1) return null;

  const Note = ({ href, id, children }: FootnoteProps) => (
    <a href={href} id={id}>
      {children}
    </a>
  );

  return (
    <ol role="doc-endnotes">
      {notes.map(note => {
        const { href, id, children } = note;
        return (
          <li role="doc-endnote" key={id}>
            <Note href={href} id={id}>
              {children}
            </Note>
          </li>
        );
      })}
    </ol>
  );
};

export default RichText;
